import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { OnboardingService } from '../utils/onboarding.service';
import { MemberCustomerService } from '../apis/member-customer.service';

@Injectable({
  providedIn: 'root',
})
export class SchedulingGuard {
  onboardingService = inject(OnboardingService);
  memberCustomerService = inject(MemberCustomerService);
  router = inject(Router);

  constructor() {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.memberCustomerService
      .geLasttMemberCustomer()
      .then(async memberCustomer => {
        console.log(`Get Member Customer`, memberCustomer);
        // Onboarding without hard-eligibility-check doesn't have member customer
        if (!memberCustomer || memberCustomer.hasInitialCall) {
          return true;
        } else {
          this.router.navigate(['/activation-flow', 'coach-intro']);
          return false;
        }
      })
      .catch(e => {
        return true;
      });
  }
}
