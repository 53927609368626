export const chronicConditions = {
  ANXIETY: 'anxiety',
  DIABETES: 'diabetes',
  DEPRESSION: 'depression',
  MOBILITY: 'difficulty-with-mobility',
  HIGH_BLOOD_PRESSURE: 'high-blood-pressure',
  HIGH_CHOLESTEROL: 'high-cholesterol',
  JOINT_PAIN: 'joint-pain',
  OVERWEIGHT: 'overweight',
  OTHER: 'other-chronic-condition',
  CHRONIC_CONDITIONS_KEY: 'chronic-conditions',
};

export const CHRONIC_CONDITIONS_KEY_PATH_MAP: {
  [key: string]: string;
} = {
  anxiety: chronicConditions.ANXIETY,
  'diabetes_or_pre-diabetes': chronicConditions.DIABETES,
  depression: chronicConditions.DEPRESSION,
  difficulty_with_mobility: chronicConditions.MOBILITY,
  heart_disease: chronicConditions.OTHER,
  high_blood_pressure: chronicConditions.HIGH_BLOOD_PRESSURE,
  high_cholesterol: chronicConditions.HIGH_CHOLESTEROL,
  joint_pain: chronicConditions.JOINT_PAIN,
  overweight: chronicConditions.OVERWEIGHT,
  other: chronicConditions.OTHER,
  extra_message: chronicConditions.OTHER,
};
