import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { CustomerPartner } from '@mightyhealth/library';
import { MemberCustomerService } from '../apis/member-customer.service';

@Injectable({
  providedIn: 'root',
})
export class ActivationFlowGuard {
  customerService = inject(MemberCustomerService);
  router = inject(Router);

  async canActivate(_: ActivatedRouteSnapshot) {
    const memberCustomer = await this.customerService.geLasttMemberCustomer();
    if (
      memberCustomer?.customerRef?.partner === CustomerPartner.OPTUM ||
      memberCustomer?.customerRef?.partner === CustomerPartner.MIGHTY
    ) {
      return true;
    } else {
      this.router.navigate(['/home']);
      return false;
    }
  }
}
