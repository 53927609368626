import { Injectable } from '@angular/core';
import { Customer, InsuranceInfo, StateCodesHelper, SubscriptionProvider } from '@mightyhealth/library';
import { OnboardingService } from './onboarding.service';
import { StorageService } from './storage.service';
import { MemberCustomerService } from '../apis/member-customer.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  static readonly customerInstanceKey: string = 'customer-instance-key';
  static readonly customerKey: string = 'customer-key';
  static readonly customerIdKey: string = 'customer-id-key';
  static readonly stateKey: string = 'state-key';
  static readonly insuranceMemberIdKey: string = 'insuance-member-id-key';

  constructor(
    private storageService: StorageService,
    private onboardingService: OnboardingService,
    private memberCustomerService: MemberCustomerService
  ) {}

  getCustomerInstance(): Customer {
    return this.storageService.get(CustomerService.customerInstanceKey);
  }

  getCustomer(): SubscriptionProvider {
    let customer = this.storageService.get(CustomerService.customerKey);
    if (customer === null) {
      customer = this.onboardingService.getOnboardingAnswerByKey(this.onboardingService.healthInsurance)?.name;
    }
    return customer;
  }

  getCustomerId(): SubscriptionProvider {
    let customerId = this.storageService.get(CustomerService.customerIdKey);
    if (customerId === null) {
      customerId = this.onboardingService.getOnboardingAnswerByKey(this.onboardingService.healthInsurance)?.id;
    }
    return customerId;
  }

  getState(): string {
    let state = this.storageService.get(CustomerService.stateKey);
    if (state === null) {
      state = this.onboardingService.getOnboardingAnswerByKey(this.onboardingService.healthInsurance)?.state;
    }
    return state;
  }

  getInsuranceMemberId(): string {
    let customer = this.storageService.get(CustomerService.insuranceMemberIdKey);
    if (customer === null) {
      customer = this.onboardingService.getOnboardingAnswerByKey(
        this.onboardingService.healthInsurance
      )?.insuranceMemberId;
    }

    return customer;
  }

  setCustomerInstance(customer: Customer): void {
    this.storageService.set(CustomerService.customerInstanceKey, customer);
  }

  setCustomer(string: SubscriptionProvider | string): void {
    this.storageService.set(CustomerService.customerKey, string);
  }

  setCustomerId(string: string): void {
    this.storageService.set(CustomerService.customerIdKey, string);
  }

  setState(string: string): void {
    this.storageService.set(CustomerService.stateKey, string);
  }

  setInsuranceMemberId(string: string): void {
    this.storageService.set(CustomerService.insuranceMemberIdKey, string);
  }

  getOnboardingInsuranceInfo(): {
    name: string;
    state: string;
    insuranceMemberId: string;
    aperoCustomerId: string;
  } {
    return this.onboardingService.getOnboardingAnswerByKey(this.onboardingService.healthInsurance);
  }

  updateInsuranceInfo(insuranceInfo?: InsuranceInfo): InsuranceInfo | undefined {
    insuranceInfo = {
      customerId: insuranceInfo?.customerId || this.getCustomerInstance()?.id,
      insuranceMemberId: insuranceInfo?.insuranceMemberId || this.getInsuranceMemberId(),
      stateCode: insuranceInfo?.stateCode || this.getState(),
    };

    if (insuranceInfo.stateCode && insuranceInfo.stateCode.length !== 2) {
      insuranceInfo.stateCode = StateCodesHelper.getStateCode(insuranceInfo.stateCode);
    }

    return insuranceInfo;
  }

  async hasScheduling(): Promise<boolean> {
    let memberCustomer = await this.memberCustomerService.geLasttMemberCustomer();

    return Boolean(memberCustomer?.hasInitialCall);
  }
}
