import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SubscriptionProvider } from '@mightyhealth/library';
import { MembersService } from '../apis/members.service';
import { OnboardingExperimentService } from '../services/onboarding-experiment.service';
import { CustomerService } from '../utils/customer.service';
import { OnboardingControllerService } from '../utils/onboarding/onboarding-controller.service';
import { OnboardingStepsService } from '../utils/onboarding/onboarding-steps-builder.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingGuard {
  flow!: string | undefined;

  constructor(
    private memberService: MembersService,
    private onboardingControllerService: OnboardingControllerService,
    private onboardingStepsService: OnboardingStepsService,
    private onboardingExperimentService: OnboardingExperimentService,
    private customerService: CustomerService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    if (this.onboardingControllerService.initialized) {
      return true;
    }

    const forceVariant = route.queryParams['variant'];
    const variant = await this.onboardingExperimentService.startOnboardingExperiment(forceVariant);
    let routes = variant.route;

    const startFrom = route.firstChild?.children[0].routeConfig?.path;
    const member = await this.memberService.getCurrentMember();
    const customerInstance = this.customerService.getCustomerInstance();
    if (member) {
      routes = routes.filter(route => !['date-of-birth', 'signup'].includes(route));
    } else if (
      customerInstance?.slug === SubscriptionProvider.MARSHFIELD ||
      customerInstance?.slug === SubscriptionProvider.CHAPTER
    ) {
      routes = routes.filter(route => !['health-insurance', 'soft-eligibility', 'hard-eligibility'].includes(route));
    }

    const steps = this.onboardingStepsService.getSteps(routes);

    await this.onboardingControllerService.init(steps, startFrom);

    this.flow = route.queryParams['flow'];

    if (this.flow === SubscriptionProvider.OPTUM.toLowerCase()) {
      this.onboardingControllerService.removeSteps(['health-insurance', 'soft-eligibility', 'hard-eligibility']);
      this.onboardingControllerService.addStep('health-insurance').before('date-of-birth');
    }

    return true;
  }
}

export const prepareOnboardingGuard = (route: ActivatedRouteSnapshot) => inject(OnboardingGuard).canActivate(route);
