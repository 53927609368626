import { Injectable } from '@angular/core';
import {
  ONBOARDING_STEPS_DEFINITION_DIRECT,
  ONBOARDING_STEPS_DEFINITION_NO_HARD_ELIGIBILITY,
  ONBOARDING_STEPS_DEFINITION_NO_HARD_ELIGIBILITY_STEPS_PAGE,
  ONBOARDING_STEPS_DEFINITION_QUESTIONNAIRE_FIRST,
} from '../pages/onboarding-v2/onboarding-steps-definition';
import { ExperimentService } from '../utils/experiment.service';

interface OnboardingVariant {
  name: string;
  route: string[];
}

const ONBOARDING_EXPERIMENT_KEY = 'web-onboarding';

// This is being used by scan, marshfield and optum onboardings
export const WEB_ONBOARDING_QUESTIONNAIRE_FIRST_VARIANT: OnboardingVariant = {
  name: 'web_onboarding_questionnaire_first_variant',
  route: ONBOARDING_STEPS_DEFINITION_QUESTIONNAIRE_FIRST,
};

const WEB_ONBOARDING_DIRECT_VARIANT: OnboardingVariant = {
  name: 'web_onboarding_direct',
  route: ONBOARDING_STEPS_DEFINITION_DIRECT,
};

const WEB_ONBOARDING_NO_HARD_ELIGIBILITY_VARIANT: OnboardingVariant = {
  name: 'web_onboarding_no_eligibility',
  route: ONBOARDING_STEPS_DEFINITION_NO_HARD_ELIGIBILITY,
};

const WEB_ONBOARDING_NO_HARD_ELIGIBILITY_STEPS_PAGE_VARIANT: OnboardingVariant = {
  name: 'web_onboarding_no_eligibility_steps_page',
  route: ONBOARDING_STEPS_DEFINITION_NO_HARD_ELIGIBILITY_STEPS_PAGE,
};

@Injectable({
  providedIn: 'root',
})
export class OnboardingExperimentService {
  constructor(private experimentService: ExperimentService) {}

  async run(): Promise<void> {
    const variant = await this.startOnboardingExperiment();
    ONBOARDING_SELECTED_VARIANT = variant;
  }

  async startOnboardingExperiment(forceVariant?: string): Promise<OnboardingVariant> {
    try {
      const variant = await this.experimentService.startExperiment(
        ONBOARDING_EXPERIMENT_KEY,
        [WEB_ONBOARDING_NO_HARD_ELIGIBILITY_VARIANT.name, WEB_ONBOARDING_NO_HARD_ELIGIBILITY_STEPS_PAGE_VARIANT.name],
        forceVariant
      );

      switch (variant) {
        case WEB_ONBOARDING_DIRECT_VARIANT.name:
          return WEB_ONBOARDING_DIRECT_VARIANT;
        case WEB_ONBOARDING_NO_HARD_ELIGIBILITY_VARIANT.name:
          return WEB_ONBOARDING_NO_HARD_ELIGIBILITY_VARIANT;
        case WEB_ONBOARDING_NO_HARD_ELIGIBILITY_STEPS_PAGE_VARIANT.name:
          return WEB_ONBOARDING_NO_HARD_ELIGIBILITY_STEPS_PAGE_VARIANT;
        default:
          return WEB_ONBOARDING_NO_HARD_ELIGIBILITY_STEPS_PAGE_VARIANT;
      }
    } catch (error) {
      console.error('Error starting onboarding experiment:', error);
      return WEB_ONBOARDING_NO_HARD_ELIGIBILITY_STEPS_PAGE_VARIANT;
    }
  }
}

export let ONBOARDING_SELECTED_VARIANT: OnboardingVariant;
