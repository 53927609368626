import { Component, ElementRef, HostBinding, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivationEnd, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Entitlements, Member } from '@mightyhealth/library';
import { MembersService } from 'src/app/apis/members.service';
import { LoginService } from 'src/app/utils/login.service';
import { ToastService } from 'src/app/utils/toast.service';

import { isPlatformServer } from '@angular/common';
import { StorageService } from '../../utils/storage.service';
import { HeaderTheme } from './header.component.constants';
import { FeatureFlagService } from '../../utils/feature-flag.service';
import { FEATURE_FLAG } from '../../utils/feature-flag.const';
import { environment } from 'src/environments/environment';
import { SubscriptionService } from '../../apis/subscription.service';

@Component({
  selector: 'mighty-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css', './themes/index.css'],
})
export class HeaderComponent implements OnInit {
  private readonly MOBILE_BREAKPOINT: number = 968;

  logged = false;
  isMenuOpen = false;
  isSubMenuOpen = false;
  isLandingPage = false;
  isGetAppPage = false;
  isMobileView = false;
  user: Partial<Member> = {
    name: undefined,
    photoUrl: undefined,
  };
  loading = true;
  currentRouteName: string = '';

  canShowHome: boolean = false;

  hasContentAccessEntitlement = false;

  @Input()
  theme: HeaderTheme = HeaderTheme.DEFAULT;

  @HostBinding('class') get hostClasses() {
    return this.theme;
  }

  @HostListener('document:click', ['$event']) documentClick($event: any) {
    this.clickOutside($event);
  }

  @HostListener('window:resize', ['$event']) onWindowResize() {
    this.checkMobileView();
  }

  someSubscription: any;

  constructor(
    private _eref: ElementRef,
    private memberService: MembersService,
    private toastService: ToastService,
    private loginService: LoginService,
    private router: Router,
    private storageService: StorageService,
    private featureFlagService: FeatureFlagService,
    private subscriptionService: SubscriptionService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.checkMobileView();
    this.memberService.subscribe(async user => {
      if (user) {
        this.logged = true;
        this.user = {
          name: user.name || user.email,
          photoUrl: user.photoUrl,
        };
        this.hasContentAccessEntitlement = await this.subscriptionService.hasEntitlement(Entitlements.CONTENT_ACCESS);
      } else {
        this.resetUser();
        this.logged = false;
      }
      this.loading = false;
    });

    this.someSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate' && event.url === '/optum/short') window.location.reload();
      }

      if (event instanceof ActivationEnd) {
        this.currentRouteName = event.snapshot.data['routeName'];
      }

      if (event instanceof NavigationEnd) {
        if (
          ['/forma', '/optum', '/optum/short', '/get-started', '/privacy-policy', '/terms-of-use'].some(urlSegment =>
            event.url.includes(urlSegment)
          )
        )
          this.isLandingPage = true;
        else this.isLandingPage = false;

        if (['/get-app'].some(urlSegment => event.url.includes(urlSegment))) this.isGetAppPage = true;
        else this.isGetAppPage = false;
      }
    });

    this.canShowHome = await this.featureFlagService.getFeatureFlag(FEATURE_FLAG.NEW_WEBHOME);

    this.hasContentAccessEntitlement = await this.subscriptionService.hasEntitlement(Entitlements.CONTENT_ACCESS);
  }

  private checkMobileView() {
    if (!isPlatformServer(this.platformId)) {
      this.isMobileView = window.innerWidth <= this.MOBILE_BREAKPOINT;
    }
  }

  private manageBodyScroll() {
    document.body.classList.toggle('hide-overflow-m');
  }

  private manageFrontChatWidgetVisibility() {
    if (this.isMenuOpen) {
      document.querySelector('#front-chat-container')?.classList.add('hidden');
    } else {
      document.querySelector('#front-chat-container')?.classList.remove('hidden');
    }
  }

  menuToggle() {
    this.isMenuOpen = !this.isMenuOpen;
    this.manageBodyScroll();
    this.manageFrontChatWidgetVisibility();
  }

  subMenuToggle() {
    this.isSubMenuOpen = !this.isSubMenuOpen;
  }

  clickOutside($event: any) {
    if (!this._eref.nativeElement.contains($event?.target)) {
      this.closeSubMenu();
      this.close();
    }
  }

  openLogin() {
    this.loginService.openModal();
  }

  close() {
    if (this.isSubMenuOpen) {
      this.isSubMenuOpen = false;
    }
    if (this.isMenuOpen) {
      this.isMenuOpen = false;
      document.body.classList.remove('hide-overflow-m');
    }
  }

  closeSubMenu() {
    if (this.isSubMenuOpen) {
      this.isSubMenuOpen = false;
    }
  }

  resetUser() {
    this.user = { name: undefined, photoUrl: undefined };
  }

  goToPreOnboarding() {
    // to be removed
    if (environment.environmentName !== 'production') {
      return this.router.navigate(['onboarding']);
    }
    return this.router.navigate(['/onboarding']);
  }

  async logout() {
    this.loading = true;
    await this.memberService.logout();
    this.toastService.addToast({
      type: 'success',
      message: "You've successfully logged out.",
    });
    this.resetUser();
    this.close();
    this.loading = false;
  }
}
