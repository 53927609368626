import { Injectable } from '@angular/core';
import { MembersService } from '../apis/members.service';
import { LandingHomePageVariantsNames } from '../pages/home/home.variants';
import { StorageService } from './storage.service';
import { TrackerService } from './tracking/tracker.service';

@Injectable({
  providedIn: 'root',
})
export class ExperimentService {
  constructor(
    private storage: StorageService,
    private tracker: TrackerService,
    private memberService: MembersService
  ) {}

  public async startExperiment(experiment: string, variants: string[], forceVariant?: string): Promise<string> {
    const variant = forceVariant ?? this.getVariant(experiment, variants);

    await this.tracker.identify(await this.memberService.getCurrentMember(), {
      [`experiment-${experiment}`]: variant,
    });

    this.storage.set(experiment, variant);
    return variant;
  }

  public getVariant(experiment: string, variants: string[]): string {
    let variant = this.storage.get(experiment);

    return variants.some(v => v === variant) ? variant : this.randomize(variants);
  }
  public async startLandingPageExperiment(forceVariant?: string): Promise<string> {
    return await this.startExperiment('landing_page', [LandingHomePageVariantsNames.DEFAULT], forceVariant);
  }

  private randomize(variants: string[]): string {
    return variants[Math.floor(Math.random() * variants.length)];
  }
}
